<template>
   <div class="auth-wrapper auth-v2">
      <b-row class="auth-inner m-0">
         <!-- Brand logo-->
         <b-link class="brand-logo">
            <h2 class="brand-text text-primary ml-1">
               <vuexy-logo />
            </h2>
         </b-link>
         <!-- /Brand logo-->

         <!-- Left Text-->
         <b-col lg="7" class="d-none d-lg-flex align-items-center p-5">
            <div
               class="w-100 d-lg-flex align-items-center justify-content-center px-5"
            >
               <b-img fluid :src="qDisplayStaticImage" alt="Login V2" />
            </div>
         </b-col>
         <!-- /Left Text-->

         <!-- Forgot password-->
         <b-col lg="5" class="d-flex align-items-center auth-bg px-2 p-lg-5">
            <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
               <b-card-title
                  title-tag="h2"
                  class="font-weight-bold mb-1 text-center"
               >
                  Mot de passe oublié? 🔒
               </b-card-title>
               <b-card-text class="mb-2 text-center">
                  Entrez votre email et nous vous enverrons des instructions
                  pour réinitialiser votre mot de passe
               </b-card-text>

               <b-form
                  class="auth-forgot-password-form mt-2"
                  @submit.prevent="qChangePassword"
               >
                  <!-- email -->
                  <b-form-group label="Email" label-for="forgot-password-email">
                     <b-form-input
                        id="forgot-password-email"
                        v-model="input.email"
                        name="forgot-password-email"
                        placeholder="john@example.com"
                     />
                     <span
                        class="text-danger"
                        style="font-size: 12px"
                        v-if="inputError.path === 'email'"
                     >
                        {{ inputError.message }}
                     </span>
                  </b-form-group>

                  <b-button type="submit" variant="primary" block>
                     <div
                        v-if="state.loading === true"
                        class="spinner-border text-primary"
                     ></div>
                     <span v-else>Réinitialiser le mot de passe</span>
                  </b-button>
               </b-form>

               <p class="text-center mt-2">
                  <b-link
                     class="flex items-center justify-center gap-4"
                     :to="{ name: 'login' }"
                  >
                     <feather-icon icon="ChevronLeftIcon" /> Retour connexion
                  </b-link>
               </p>
            </b-col>
         </b-col>
         <!-- /Forgot password-->
      </b-row>
   </div>
</template>

<script>
/* eslint-disable global-require */
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
   BRow,
   BCol,
   BLink,
   BCardTitle,
   BCardText,
   BImg,
   BForm,
   BFormGroup,
   BFormInput,
   BButton,
} from 'bootstrap-vue';
import URL from '@/views/pages/request';
import axios from 'axios';
import { onMounted, reactive, computed } from '@vue/composition-api';
import store from '@/store/index';
export default {
   components: {
      VuexyLogo,
      BRow,
      BCol,
      BLink,
      BImg,
      BForm,
      BButton,
      BFormGroup,
      BFormInput,
      BCardTitle,
      BCardText,
   },

   setup(props, {root}) {
      // Data
      const state = reactive({
         loading: false,
         sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      });
      const input = reactive({
         email: '',
      });
      const inputError = reactive({
         path: '',
         message: '',
      });

      //Methods Monter
      onMounted(() => {
         document.title = 'Ediqia - Rénitialisation de mot de passe';
      });

      // Methods Calculer
      const qDisplayStaticImage = computed(() => {
         if (store.state.appConfig.layout.skin === 'dark') {
            state.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
            return state.sideImg;
         }
         return state.sideImg;
      });

      // Methods

      // VALIDATE INPUT PASSWORD
      const qValidateResetPassword = async (request) => {
         let error = false;
         const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

         if (request.email === '') {
            return {
               error: true,
               EI: {
                  path: 'email',
                  message: "L'adresse email est requis !",
               },
            };
         } else if (!regexEmail.test(request.email)) {
            return {
               error: true,
               EI: {
                  path: 'email',
                  message: 'Entrez une adresse mail valide...',
               },
            };
         }

         return {
            error: error,
            EI: {
               path: '',
               message: '',
            },
         };
      };

      // CHANGE PASSWORD
      const qChangePassword = async () => {
         state.loading = true;
         try {
            const xDataRestPassword = {
               email: input.email,
            };

            const { error, EI } = await qValidateResetPassword(input);
            inputError.path = EI.path;
            inputError.message = EI.message;

            if (!error) {
               const { data } = await axios.post(
                  URL.EMAIL_RESET_PASSWORD,
                  xDataRestPassword,
                  {
                     headers: {
                        Accept: 'application/json',
                     },
                  }
               );


               if (data) {
                  state.loading = false;
                  inputError.path = '';
                  inputError.message = '';

                  root.$router.replace({
                     name: 'new-password',
                     params: { token: data.token, email: input.email },
                  });
               }
            }
         } catch (error) {
            state.loading = false;
            console.log(error.response.data);
         }
      };

      return {
         state,
         input,
         inputError,
         qChangePassword,
         qDisplayStaticImage,
      };
   },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
